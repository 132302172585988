import 'invariant';
import { Map, fromJS } from 'immutable';
import createApiCallHash from '@dbh/create-api-call-hash';
import { LOAD_WEBPAGES_ROUTES_ERROR, LOAD_WEBPAGES_ROUTES_SUCCESS, LOAD_WEBPAGES_ERROR, LOAD_WEBPAGES_SUCCESS } from '@dbh/webpages-events-and-constants-redux';
export { REDUX_AND_SAGA_KEY } from '@dbh/webpages-events-and-constants-redux';
import { withReducerUserLoginStatusChangedSsrHandler } from './index.js';
import 'prop-types';
import '@dbh/environment';
import '@dbh/with-conforms-to-for-production-www';
import '@dbh/user-area-redux';
import 'lodash/endsWith';
import 'lodash/startsWith';
import '@dbh/throw-in-server-side-rendering';
import 'redux-saga/effects';
import 'react-router-redux';
import '@dbh/admindev-constants';
import '@dbh/routing-redux';
import '@dbh/urls';
import '@dbh/saga-request-options';
import '@dbh/daybreak-hero-redux';
import '@dbh/api-constants';
import '@dbh/circular-webpages-redirect-checker-browser';
import '@dbh/sentry';
import 'reselect';
import '@dbh/reselect-extra';
import '@dbh/ssr-data-redux';
import 'lodash/isString';
import 'react-immutable-proptypes';
import '@dbh/lodash-extra';
import '@dbh/logger-redux';
import '@dbh/not-found-page-webcontrols';
import 'lodash/trim';
import '@dbh/make-api-url';
import '@dbh/search-context-types';
import '@dbh/front-end-logic-redirect-redux';
import 'date-fns/isDate';
import '@dbh/admindev-types';
import 'lodash/noop';
import '@dbh/configuration-redux';
import '@dbh/redux-saga-extra';
import '@dbh/generic-types';
import '@dbh/invariant-errors-and-warnings';

const initialState=Map({webpages:Map(),webpagesLoaded:Map(),webpagesRoutes:Map(),webpagesRoutesLoaded:Map(),error:Map(),webpagesApiCallsHashes:Map(),webpagesRoutesErrors:Map()}),webpagesReducer=function(a,b){void 0===a&&(a=initialState);const{type:c}=b;switch(c){case LOAD_WEBPAGES_SUCCESS:{const{payload:{locale:c,country:d,pathname:e,webpages:f,userAreaData:g,fromCache:h,isUserLoaded:i}}=b;if(h)return a;const j=[e],k=i?createApiCallHash(g):"";return a.withMutations(a=>a.setIn(["webpages",...j],f// `webpages` is already immutable.
).setIn(["webpagesLoaded",...j],!0).setIn(["webpagesApiCallsHashes",...j],k))}case LOAD_WEBPAGES_ERROR:{const{payload:{country:c,locale:d,error:e}}=b;return a.setIn(["error",...[c,d]],e)}// The following cases handle the: `webpagesRoutes`.
case LOAD_WEBPAGES_ROUTES_SUCCESS:{const{payload:{webpagesRoutes:c,country:d,locale:e}}=b,f=[d,e];return a.withMutations(a=>a.setIn(["webpagesRoutes",...f],fromJS(c)).setIn(["webpagesRoutesLoaded",...f],!0).deleteIn(["webpagesRoutesErrors",...f]))}case LOAD_WEBPAGES_ROUTES_ERROR:{const{payload:{error:c,country:d,locale:e}}=b;return a.setIn(["webpagesRoutesErrors",...[d,e]],c)}default:return a}};var reducer = withReducerUserLoginStatusChangedSsrHandler("webpages",webpagesReducer);

export { reducer as default };
